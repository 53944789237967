/**
 * Created by david on 23/01/2017.
 */
import React, { Component } from 'react';
import './Footer.css'

class Footer extends Component {
    render() {
        return (
            <div className="FooterContainer">
                <div className="FooterLinkContainer">
                    <a className="FooterLinkText" href="https://endofleaseccb.com.au/book">Book Online</a>
                </div>
            </div>
        );
    }
}

export default Footer;
