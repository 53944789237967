import React, { Component } from 'react';
import './EventView.css';
import SimpleReactValidator from 'simple-react-validator';

const styles = {
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    }
};

class EventView extends Component {

    constructor(props) {
        super();
        
        this.validator = new SimpleReactValidator({
            className: 'errorText'
        });

        this.state = {
            event: null,
        };

        this.onFrameClick = this.onFrameClick.bind(this);
    }

    static getDerivedStateFromProps(nextProps) {    
        return {
            event: nextProps.event,
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
  
        this.setState({
            [name]: value
        });
    }

    onDismiss = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.props.onDone(this.state.event)
    }

    onDelete = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.props.onDelete(this.state.event)
    }

    onFrameClick(event) {
        event.stopPropagation();
    }

    render() {
        let style = styles.hidden;
        if (this.props.show) {
            style = styles.visible;
        }

        var titleText = "";
        var addressText = "";
        var phoneText = "";
        var roomsText = ""
        if (this.props.event != null) {
            titleText = this.props.event.name == null ? this.props.event.title : `Name: ${this.props.event.name}`;
            addressText = this.props.event.address == null ? "Address: -" : `Address: ${this.props.event.address}`;
            phoneText = this.props.event.phoneNumber == null ? "Phone: -" : `Phone: ${this.props.event.phoneNumber}`;
            roomsText = this.props.event.numberOfRooms == null ? "Rooms: -" : `Rooms: ${this.props.event.numberOfRooms}`;
        }

        return(
            <div className="eventViewBackgroundView" style={style} onClick={this.onDismiss}>
                <div className="eventViewFrame" onClick={this.onFrameClick}>
                    <div className="eventTitleText">{titleText}</div>
                    <div className="eventTitleText">{addressText}</div>
                    <div className="eventTitleText">{phoneText}</div>
                    <div className="eventTitleText">{roomsText}</div>
                    <button className="deleteButton" type="submit" value="Submit" onClick={this.onDelete}>{"Delete"}</button>
                </div>
            </div>
        );
    }
}

export default EventView;