/**
 * Created by davidcaddy on 12/12/2016.
 */

import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import React, { Component } from 'react';
import './LogInOutViews.css';
import SimpleReactValidator from 'simple-react-validator';

const auth = getAuth();

const styles = {
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    }
};

class LogInView extends Component {

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            className: 'errorText'
        });

        this.state = {
            email: props.fillUsername,
            password: props.fillPassword
        };

        this.onBackgroundViewClick = this.onBackgroundViewClick.bind(this);
        this.onFrameClick = this.onFrameClick.bind(this);
        this.signUp = this.signUp.bind(this);
        this.logIn = this.logIn.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.fillUsername == null && props.fillPassword == null) {
            return null;
        }

        return {
            email: props.fillUsername,
            password: props.fillPassword
        };
    }

    onBackgroundViewClick(event) {
        this.props.onDone();
    }

    onFrameClick(event) {
        event.stopPropagation();
    }

    signUp(event) {
        event.preventDefault();
        if (this.validator.allValid()) {
            createUserWithEmailAndPassword(auth, this.state.email, this.state.email).catch(function(error) {
                // Handle Errors here.
                // var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorMessage);
              });
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
        event.stopPropagation();
    }

    logIn(event) {
        event.preventDefault();
        if (this.validator.allValid()) {
            signInWithEmailAndPassword(auth, this.state.email, this.state.password);
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
        event.stopPropagation();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
  
        this.setState({
          [name]: value
        });
    }

    render() {
        let style = styles.hidden;
        if (this.props.show) {
            style = styles.visible;
        }

        return(
            <div className="loginBackgroundView" onClick={this.onBackgroundViewClick} style={style}>
                <form className="loginFrame" onClick={this.onFrameClick}>
                    {/* <div id="firebaseui-auth"></div> */}
                    <input name="email" className="emailField" type="email" placeholder="Email" value={this.state.email || ""} onChange={this.handleInputChange}/>
                    {this.validator.message('email', this.state.email, 'required|email')}
                    <input name="password" className="passwordField" type="password" placeholder="Password" value={this.state.password || ""} onChange={this.handleInputChange}/>
                    {this.validator.message('password', this.state.password, 'required|alpha_num_dash_space|min:6')}
                    <button className="loginButton" type="submit" value="Submit" onClick={this.logIn}>{"Log in"}</button>
                </form>
            </div>
        );
    }
}

export default LogInView;