import React from 'react';
import {Route, Switch } from 'react-router-dom'
import HomePage from './Pages/HomePage';
import ContactPage from './Pages/ContactPage';
import AboutPage from './Pages/AboutPage';
import BookingPage from './Pages/BookingPage';
import BookingUnavailablePage from './Pages/BookingUnavailablePage';
import CalendarPage from './Pages/CalendarPage';

function MainRouter() {
    return (
      <main style = {{height:"100%", width:"100%"}}> 
        <Switch>
            <Route path="/" component={HomePage} exact/>
            <Route path="/contact" component={ContactPage} exact/>
            <Route path="/about" component={AboutPage} exact/>
            <Route path="/book" component={BookingPage} exact/>
            <Route path="/book-unavailable" component={BookingUnavailablePage} exact/>
            <Route path="/calendar" component={CalendarPage} exact/>
        </Switch>
      </main>
    );
}

export default MainRouter;
