import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import './BookingPage.css';
import './ContactPage.css';
import DatePicker from "react-datepicker";
import SimpleReactValidator from 'simple-react-validator';
import NavBar from '../Components/NavBar';

import "react-datepicker/dist/react-datepicker.css";

import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore, Timestamp, collection, addDoc, doc, getDoc, query, where, onSnapshot } from "firebase/firestore";

let config = {
    apiKey: "AIzaSyDvmsVvmzscjXmYC7kvFsvWm4tABXg2J7g",
    authDomain: "endofleaseccb.com.au",
    databaseURL: "https://end-of-lease-ccb.firebaseio.com",
    projectId: "end-of-lease-ccb",
    storageBucket: "end-of-lease-ccb.appspot.com",
    messagingSenderId: "61871480016",
    appId: "1:61871480016:web:15a9c46d7276c90d4752d5",
    measurementId: "G-1LH2202L19"
};

const app = getApps().length ? getApp() : initializeApp(config);
export const db = getFirestore(app);

class BookingPage extends Component {
    constructor(props) {
        super();

        this.validator = new SimpleReactValidator({
          className: 'errorText',
          messages: {
            regex: 'The phone number must be a valid phone number.'
          }
        });

        this.state = {
          bookingEnabled: true,
          formSubmitted: false,
          firstName: null,
          lastName: null,
          email: null,
          address: null,
          phoneNumber: null,
          numberOfRooms: 0,
          comments: null,
          message: "Sending...",
          date: null,
          timeSlot: null,
          carpetCondition: 0,
          bookings: []
        };

        this.googleReportConversion = this.googleReportConversion.bind(this);
        this.openUrl = this.openUrl.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setDate = this.setDate.bind(this);
        this.addDays = this.addDays.bind(this);
        this.filterDate = this.filterDate.bind(this);
        this.availableTimeSlots = this.availableTimeSlots.bind(this);
    }

    googleReportConversion(url) {
      window.gtag_report_form_submission_conversion();
    }

    componentDidMount() {
      getDoc(doc(db, "config", "config")).then(doc => {
        if (doc.exists) {
          let config = doc.data();
          if (!config.bookingEnabled) {
            this.setState({
              bookingEnabled: false
            });
            this.openUrl("/book-unavailable");
          }
        } 
        else {
           console.log("No config.");
        }
      }).catch(error => {
          console.log("Error getting config:", error);
      });

      const q = query(collection(db, "appointments"), where("end", ">=", Timestamp.now()));
      onSnapshot(q, querySnapshot  => {
        var events = [];
        querySnapshot.forEach(doc => {
          let bookingData = doc.data();
          let booking = {
              title: bookingData.title,
              start: bookingData.start.toDate(),
              end: bookingData.end.toDate()
          };
          events.push(booking);
        });
        this.setState({
            bookings: events
        });
      });
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }

    setDate(date) {
      this.setState({
        date: date,
        timeSlot: null
      });
    }

    addDays(date, days) {
      return date.setDate(date.getDate() + days); 
    }

    filterDate(date) {
      let dayOfWeek = date.getDay();
      if ((dayOfWeek === 0) || (dayOfWeek === 6)) {
        return false
      }

      if (this.availableTimeSlots(date).length <= 0) {
        return false
      }

      return true;
    }

    availableTimeSlots(date) {
      if (date == null) {
        return [];
      }

      let dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      let dateTime = dateWithoutTime.getTime();
      let earlyMorning = new Date(dateTime);
      earlyMorning.setHours(9);
      let lateMorning = new Date(dateTime);
      lateMorning.setHours(11);
      let afternoon = new Date(dateTime);
      afternoon.setHours(13);
      let endOfDay = new Date(dateTime);
      endOfDay.setHours(23);
      endOfDay.setMinutes(59);
      endOfDay.setSeconds(59);

      let earlyMorningTime = earlyMorning.getTime();
      let lateMorningTime = lateMorning.getTime();
      let afternoonTime = afternoon.getTime();
      let endOfDayTime = endOfDay.getTime();

      var unavailableSlots = [];
      for (let event of this.state.bookings) {
        let eventStartTime = event.start.getTime();
        let eventEndTime = event.end.getTime();

        if ((eventStartTime <= earlyMorningTime) && (eventEndTime >= earlyMorningTime)) {
          unavailableSlots.push(0);
        }
        else if ((eventStartTime > earlyMorningTime) && ((eventEndTime < lateMorningTime))) {
          unavailableSlots.push(0);
        }

        if ((eventStartTime <= lateMorningTime) && ((eventEndTime >= lateMorningTime))) {
          unavailableSlots.push(1);
        }
        else if ((eventStartTime > lateMorningTime) && ((eventEndTime < afternoonTime))) {
          unavailableSlots.push(1);
        }

        if ((eventStartTime <= afternoonTime) && ((eventEndTime >= afternoonTime))) {
          unavailableSlots.push(2);
        }
        else if ((eventStartTime > afternoonTime) && ((eventEndTime < endOfDayTime))) {
          unavailableSlots.push(2);
        }
      }

      var availableSlots = [];
      for (var i = 0; i < 3; i++) { 
        if (unavailableSlots.indexOf(i) <= -1) {
          availableSlots.push(i);
        }
      }
      return availableSlots;
    }

    handleSubmit(event) {
      if (!this.state.bookingEnabled) {
        event.preventDefault();
        return;
      }

      if (this.validator.allValid()) {
        let jobStartDate = new Date(this.state.date.getFullYear(), this.state.date.getMonth(), this.state.date.getDate());
        if (this.state.timeSlot == 0) {
          jobStartDate.setHours(9);
        }
        else if (this.state.timeSlot == 1) {
          jobStartDate.setHours(11);
        }
        else if (this.state.timeSlot == 2) {
          jobStartDate.setHours(13);
        }
        // Add 1:59
        var jobEndDate = new Date(jobStartDate.getTime());
        jobEndDate.setTime(jobStartDate.getTime() + (60*60*1000) + (59*60*1000));

        var name = this.state.firstName + " " + this.state.lastName;
        addDoc(collection(db, "appointments"), {
          name: name,
          email: this.state.email,
          address: this.state.address,
          phoneNumber: this.state.phoneNumber,
          numberOfRooms: this.state.numberOfRooms,
          carpetCondition: this.state.carpetCondition,
          start: Timestamp.fromDate(jobStartDate),
          end: Timestamp.fromDate(jobEndDate),
          comments: this.state.comments,
          title: `${this.state.address}\n${name} (${this.state.numberOfRooms})\n${this.state.phoneNumber}`,
          simpleTitle: `${this.state.address} | ${name}`,
          created: Timestamp.now()
        })
        .then(docRef => {
          this.googleReportConversion();
          this.setState({
            message: "Thank you, a summary of your booking has been sent to your email address.",
            formSubmitted: true
          });
        })
        .catch(error => {
          this.setState({
            message: "Something went wrong, please refresh the page and try again, or call: 0419 337 466",
            formSubmitted: true
          });
        });
      } 
      else {
        this.validator.showMessages();
        this.forceUpdate();
      }

      event.preventDefault();
    }

    openUrl(url) {
      window.open(url, "_self");
    }

    render() {
      var numberOfRoomsTitle = "Number of Rooms:";
      if (this.state.numberOfRooms > 0) {
        var roomsText = "room";
        if (this.state.numberOfRooms > 1) {
          roomsText = "rooms";
        }
        if (this.state.numberOfRooms > 5) {
          numberOfRoomsTitle = `For ${this.state.numberOfRooms} ${roomsText}, estimated cost is $${40 + this.state.numberOfRooms * 30}`
        }
        else {
          numberOfRoomsTitle = `For ${this.state.numberOfRooms} ${roomsText}, estimated cost is $${90 + this.state.numberOfRooms * 20}`
        }
      }

      let timeOptions = [];
      let availableSlots = this.availableTimeSlots(this.state.date);
      let labels = ["9 AM ", "11 AM ", "1 PM"];
      for (var i = 0; i < labels.length; i++) {
        if (availableSlots.indexOf(i) > -1) {
          timeOptions.push(<div key={labels[i]}><input id={labels[i]} type="radio" value={i} name="timeSlot" checked={this.state.timeSlot == i} onChange={this.handleInputChange}/><label htmlFor={labels[i]}>{labels[i]}</label></div>)
        }
        else {
          timeOptions.push(<div key={labels[i]}><input id={labels[i]} key={labels[i]} type="radio" value={i} name="timeSlot" checked={this.state.timeSlot == i} onChange={this.handleInputChange} disabled/><label htmlFor={labels[i]}>{labels[i]}</label></div>)
        }
      }

      let hideForm = this.state.formSubmitted || (this.state.date == null) || (this.state.timeSlot == null);

      return (
        <div className="bookingPage">
          <Helmet>
            <title>End of Lease Carpet Cleaning Bendigo | Contact Us</title>
            <meta name="description" content="End of Lease Carpet Cleaning Bendigo contact form. Free quote." />
          </Helmet>
          <NavBar tabData={[]} titleTop={"End of Lease"} titleBottom={"Carpet Cleaning Bendigo"} mainAction={() => this.openUrl('/')}/>
          <div className={this.state.formSubmitted ? "messageText" : "hidden"}>{this.state.message}</div>
          <div className={this.state.formSubmitted ? "messageText" : "hidden"}><i>Please check your spam folder if it doesn't arrive shortly.</i></div>
          <div className={this.state.formSubmitted ? "messageText" : "hidden"}>If you need to reschedule, please call <b>0419 337 466</b>.</div>
          <div className={this.state.formSubmitted ? "hidden" : "messageText"}>End of lease carpet cleaning specialist.</div>
          <div className={this.state.formSubmitted ? "hidden" : "messageText"}>To book call <b>0419 337 466</b> or start by selecting a time that works for you.</div>
          <div className={this.state.formSubmitted ? "hidden" : "messageText"}><i>Please call to check availability if preferred time is within 7 days.</i></div>

          <div className={this.state.formSubmitted ? "hidden" : "topContainer"}>
            <table className="priceContainer">
              <tbody>
                  <tr>
                    <td>1 Room</td>
                    <td>$110</td>
                  </tr>
                  <tr>
                    <td>2 Rooms</td>
                    <td>$130</td>
                  </tr>
                  <tr>
                    <td>3 Rooms</td>
                    <td>$150</td>
                  </tr>
                  <tr>
                    <td>4 Rooms</td>
                    <td>$170</td>
                  </tr>
                  <tr>
                    <td>5 Rooms</td>
                    <td>$190</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      Add $45 For Stairs
                    </td>
                  </tr>
                </tbody>
              </table>
            <div className="calendarContainer">
              <DatePicker showPopperArrow={false} 
              disabledKeyboardNavigation
              inline
              dateFormat="dd/MM/yyyy"
              selected={this.state.date}
              closeOnScroll={true} 
              filterDate={this.filterDate}
              onChange={date => this.setDate(date)} 
              minDate={this.addDays(new Date(), 7)}
              maxDate={this.addDays(new Date(), 42)}
              customInput={
                <input type="text"/>
              }/>
              <div className="timeContainer">
                {timeOptions}
              </div>
            </div>
          </div>
          
          <form className={hideForm ? "hidden" : "form"} onSubmit={this.handleSubmit}>
            <div>
              <label className="firstNameContainer">
                <div className="formLabelText">First Name:</div>
                <input
                  autoComplete="given-name"
                  maxLength="20"
                  name="firstName"
                  type="text"
                  value={this.state.firstName || ""}
                  onChange={this.handleInputChange}/>
                {this.validator.message('firstName', this.state.firstName, 'required|alpha_space')}
              </label>
              <label className="lastNameContainer">
                <div className="formLabelText">Last Name:</div>
                <input
                  autoComplete="family-name"
                  maxLength="20"
                  name="lastName"
                  type="text"
                  value={this.state.lastName || ""}
                  onChange={this.handleInputChange}/>
                {this.validator.message('lastName', this.state.lastName, 'required|alpha_space')}
              </label>
            </div>
            <div className="numberOfRoomsDescriptionText"><i>Full name for receipt as on lease agreement REQUIRED</i></div>
            <label>
              <div className="formLabelText">Email:</div>
              <input
                maxLength="50"
                name="email"
                type="text"
                value={this.state.email || ""}
                onChange={this.handleInputChange}/>
              {this.validator.message('email', this.state.email, 'required|email')}
            </label>
            <label>
              <div className="formLabelText">Address:</div>
              <input
                maxLength="100"
                name="address"
                type="text"
                value={this.state.address || ""}
                onChange={this.handleInputChange}/>
              {this.validator.message('address', this.state.address, 'required')}
            </label>
            <label>
              <div className="formLabelText">Phone Number:</div>
              <input
                maxLength="20"
                name="phoneNumber"
                type="text"
                value={this.state.phoneNumber || ""}
                onChange={this.handleInputChange}/>
                {this.validator.message('phone_number', this.state.phoneNumber, ['required', {regex: '^(\\+?\\(61\\)|\\(\\+?61\\)|\\+?61|\\(0[1-9]\\)|0[1-9])?( ?-?[0-9]){7,9}$'}])}
            </label>
            <label>
              <div className="formLabelText">{numberOfRoomsTitle}</div>
              <div className="numberOfRoomsDescriptionText"><i>Hallways and wardrobes are free!</i></div>
              <div className="numberOfRoomsDescriptionText"><i>Add $45 for stairs.</i></div>
              <select className="selector" name="numberOfRooms" value={this.state.numberOfRooms} onChange={this.handleInputChange}>
                <option value={0}>-</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
              </select>
              {this.validator.message('number_of_rooms', this.state.numberOfRooms, 'required|min:1,num')}
            </label>
            <label>
              <div className="formLabelText">Carpet Condition:</div>
              <select className="selector" name="carpetCondition" value={this.state.carpetCondition} onChange={this.handleInputChange}>
                <option value={"-"}>-</option>
                <option value={"Excellent"}>Excellent</option>
                <option value={"Very Good"}>Very Good</option>
                <option value={"Average"}>Average</option>
              </select>
            </label>
            <label>
              <div className="formLabelText">Comments:</div>
              <textarea maxLength="300" name="comments" value={this.state.comments || ""} onChange={this.handleInputChange}/>
            </label>
            <br />
            <div className="numberOfRoomsDescriptionText"><i>Payment: Cash on day or bank transfer. Receipt supplied.</i></div>
            <input className="submitButton" type="submit" value="Book" />
            <div className="numberOfRoomsDescriptionText"><i>Owner/tenant present or leave key or house open. You do not have to be there.</i></div>
            <div className="numberOfRoomsDescriptionText"><i>Booking may be cancelled at anytime.</i></div>
            <br />
          </form>
        </div>
      );
    }
}

export default BookingPage;