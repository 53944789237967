import React, { Component } from 'react';
import './EventView.css';
import '../Pages/BookingPage.css';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const styles = {
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    }
};

class NewEventView extends Component {

    constructor(props) {
        super();
        
        this.validator = new SimpleReactValidator({
            className: 'errorText'
        });

        this.state = {
            title: null,
            startDate: null,
            endDate: null,
            timeSlot: null
        };

        this.resetValues = this.resetValues.bind(this);
        this.onFrameClick = this.onFrameClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setDate = this.setDate.bind(this);
    }

    resetValues() {
        this.setState({
            title: null,
            startDate: null,
            endDate: null,
            timeSlot: null
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
  
        this.setState({
            [name]: value
        });
    }

    onDismiss = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.props.onDone(this.state.event)
    }

    onCreate = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if ((this.state.startDate == null) || ((this.state.endDate == null) && (this.state.timeSlot == null))) {
            this.props.onCreate(null)
        }
        else {
            var start = this.state.startDate;
            var end = this.state.endDate;

            if (end == null) {
                let dateWithoutTime = new Date(start.getFullYear(), start.getMonth(), start.getDate());
                let dateTime = dateWithoutTime.getTime();

                if (this.state.timeSlot == 0) {
                    start = new Date(dateTime);
                    start.setHours(9)
                    end = new Date(start.getTime());
                    end.setTime(start.getTime() + (60*60*1000) + (59*60*1000));
                }
                else if (this.state.timeSlot == 1) {
                    start = new Date(dateTime);
                    start.setHours(11)
                    end = new Date(start.getTime());
                    end.setTime(start.getTime() + (60*60*1000) + (59*60*1000));
                }
                else if (this.state.timeSlot == 2) {
                    start = new Date(dateTime);
                    start.setHours(13)
                    end = new Date(start.getTime());
                    end.setTime(start.getTime() + (60*60*1000) + (59*60*1000));
                }
                else if (this.state.timeSlot == 3) {
                    start = new Date(dateTime);
                    start.setHours(8)
                    end = new Date(start.getTime());
                    end.setTime(start.getTime() + (60*60*1000*9));
                }
            }
            else {
                let startDateWithoutTime = new Date(start.getFullYear(), start.getMonth(), start.getDate());
                let endDateWithoutTime = new Date(end.getFullYear(), end.getMonth(), end.getDate());

                start = new Date(startDateWithoutTime.getTime());
                end = new Date(endDateWithoutTime.getTime());
            }

            let title = this.state.title == null ? "Untitled" : this.state.title
            let entry = {
                title: title,
                start: start,
                end: end
            }

            this.props.onCreate(entry)
            this.resetValues()
        }
    }

    onFrameClick(event) {
        event.stopPropagation();
    }

    setDate(dates) {
        const [start, end] = dates;
        this.setState({
          startDate: start,
          endDate: end
        });
      }

    render() {
        let style = styles.hidden;
        if (this.props.show) {
            style = styles.visible;
        }

        var title = "";
        if (this.props.event != null) {
            title = this.props.event.title;
        }

        let timeOptions = [];
        if (this.state.startDate != null) {
            let labels = ["9 AM ", "11 AM ", "1 PM", "All"];
            for (var i = 0; i < labels.length; i++) {
                timeOptions.push(<div key={labels[i]}><input id={labels[i]} type="radio" value={i} name="timeSlot" checked={this.state.timeSlot == i} onChange={this.handleInputChange}/><label htmlFor={labels[i]}>{labels[i]}</label></div>)
            }
        }

        return(
            <div className="eventViewBackgroundView" style={style} onClick={this.onDismiss}>
                <div className="eventViewFrame" onClick={this.onFrameClick}>
                    <label>
                    <div className="formLabelText">Title:</div>
                        <input
                            name="title"
                            type="text"
                            value={this.state.title || ""}
                            onChange={this.handleInputChange}/>
                    </label>
                    <div className="eventViewSpacer"/>
                    <DatePicker
                        disabledKeyboardNavigation
                        selected={this.state.startDate}
                        onChange={dates => this.setDate(dates)}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        selectsRange
                        inline
                    />
                    <div className={(this.state.endDate == null) || (this.state.startDate.getTime() == this.state.endDate.getTime()) ? "timeContainer" : "hidden"}>
                        {timeOptions}
                    </div>
                    <div className="eventTitleText">{title}</div>
                    <button className="addButton" type="submit" value="Submit" onClick={this.onCreate}>{"Create"}</button>
                </div>
            </div>
        );
    }
}

export default NewEventView;