import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import './ContactPage.css';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import NavBar from '../Components/NavBar';

class ContactPage extends Component {
    constructor() {
      super();

      this.validator = new SimpleReactValidator({
        className: 'errorText',
        messages: {
          regex: 'The phone number must be a valid phone number.'
        }
      });

      this.state = {
        formSubmitted: false,
        name: null,
        email: null,
        address: null,
        phoneNumber: null,
        numberOfRooms: 0,
        comments: null,
        message: "Sending..."
      };

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.openUrl = this.openUrl.bind(this);
      this.googleReportConversion = this.googleReportConversion.bind(this);
    }

    googleReportConversion(url) {
      window.gtag_report_form_submission_conversion();
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }

    handleSubmit(event) {
      if (this.validator.allValid()) {
        var url = "https://us-central1-end-of-lease-ccb.cloudfunctions.net/sendMail";
        url = `${url}?dest=endofleaseccb@gmail.com`;
        // url = `${url}?dest=davoc@me.com`;
        url = `${url}&title=Form Submission`;

        let comments = (this.state.comments || "");
        let htmlMessage = `
          <p><strong>Name: </strong>${this.state.name}</p>
          <p><strong>Email: </strong>${this.state.email}</p>
          <p><strong>Address: </strong>${this.state.address}</p>
          <p><strong>Phone Number: </strong>${this.state.phoneNumber}</p>
          <p><strong>Number of Rooms: </strong>${this.state.numberOfRooms}</p>
          <p><strong>Comments: </strong><br />${comments.replace(/\n/g, "<br />")}</p>
        `;
        let rawMessage = {
          "name": this.state.name,
          "email": this.state.email,
          "address": this.state.address,
          "phoneNumber": this.state.phoneNumber,
          "numberOfRooms": this.state.numberOfRooms,
          "comments": comments
        };
        let body = {"message": htmlMessage, "rawMessage": rawMessage};

        axios.post(url, body).then(res => {
          this.googleReportConversion();
          this.setState({
            message: "Thank you, someone will be in touch shortly to confirm quote and time."
          });
        }).catch((error) => {
          this.setState({
            message: "Something went wrong, please refresh the page and try again, or call: 0419 337 466"
          });
        });

        this.setState({
          formSubmitted: true
        });
      } 
      else {
        this.validator.showMessages();
        this.forceUpdate();
      }

      event.preventDefault();
    }

    openUrl(url) {
      window.open(url, "_self");
    }

    render() {
      let tabData = [
        // {title: "Home", action: () => this.openUrl('/')}
      ];

      var numberOfRoomsTitle = "Number of Rooms:";
      if (this.state.numberOfRooms > 0) {
        var roomsText = "room";
        if (this.state.numberOfRooms > 1) {
          roomsText = "rooms";
        }
        if (this.state.numberOfRooms > 5) {
          numberOfRoomsTitle = `For ${this.state.numberOfRooms} ${roomsText}, estimated cost is $${20 + this.state.numberOfRooms * 30}`
        }
        else {
          numberOfRoomsTitle = `For ${this.state.numberOfRooms} ${roomsText}, estimated cost is $${70 + this.state.numberOfRooms * 20}`
        }
      }
      
      return (
        <div className="contactPage">
          <Helmet>
            <title>End of Lease Carpet Cleaning Bendigo | Contact Us</title>
            <meta name="description" content="End of Lease Carpet Cleaning Bendigo contact form. Free quote." />
          </Helmet>
          <NavBar tabData={tabData} titleTop={"End of Lease"} titleBottom={"Carpet Cleaning Bendigo"} mainAction={() => this.openUrl("/")}/>
          <div className={this.state.formSubmitted ? "messageText" : "hidden"}>{this.state.message}</div>
          <div className={this.state.formSubmitted ? "hidden" : "messageText"}>To request a quote please use the form below or call <b>0419 337 466.</b></div>
          <form className={this.state.formSubmitted ? "hidden" : "form"} onSubmit={this.handleSubmit}>
            <label>
              <div className="formLabelText">Name:</div>
              <input
                name="name"
                type="text"
                value={this.state.name || ""}
                onChange={this.handleInputChange}/>
                  {this.validator.message('name', this.state.name, 'required|alpha_space')}
            </label>
            <label>
              <div className="formLabelText">Email:</div>
              <input
                name="email"
                type="text"
                value={this.state.email || ""}
                onChange={this.handleInputChange}/>
              {this.validator.message('email', this.state.email, 'email')}
            </label>
            <label>
              <div className="formLabelText">Address:</div>
              <input
                name="address"
                type="text"
                value={this.state.address || ""}
                onChange={this.handleInputChange}/>
              {this.validator.message('address', this.state.address, 'required')}
            </label>
            <label>
              <div className="formLabelText">Phone Number:</div>
              <input
                name="phoneNumber"
                type="text"
                value={this.state.phoneNumber || ""}
                onChange={this.handleInputChange}/>
                {this.validator.message('phone_number', this.state.phoneNumber, ['required', {regex: '^(\\+?\\(61\\)|\\(\\+?61\\)|\\+?61|\\(0[1-9]\\)|0[1-9])?( ?-?[0-9]){7,9}$'}])}
            </label>
            <label>
              <div className="formLabelText">{numberOfRoomsTitle}</div>
              <div className="numberOfRoomsDescriptionText"><i>Hallways and wardrobes are free!</i></div>
              <div className="numberOfRoomsDescriptionText"><i>Add $45 for stairs.</i></div>
              <select className="selector" name="numberOfRooms" value={this.state.numberOfRooms} onChange={this.handleInputChange}>
                <option value={0}>-</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
              </select>
              {this.validator.message('number_of_rooms', this.state.numberOfRooms, 'required|min:1,num')}
            </label>
            <label>
              <div className="formLabelText">Comments:</div>
              <textarea name="comments" value={this.state.comments || ""} onChange={this.handleInputChange}/>
            </label>
            <br />
            <input className="submitButton" type="submit" value="Submit" />
          </form>
        </div>
      );
    }
}

export default ContactPage;